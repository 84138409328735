import { DevicePlatformService } from './device-platform.service';
import { DebuggingService, Log } from './debugging.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage';
import { ReaderService, Reader } from './reader.service';
// import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class EbookAuthService {
  authState = new BehaviorSubject(false);
  webExit = new BehaviorSubject(false);
  newLog: Log = {UserId: '', Function: '', Error: '', Time: null, id: ''};
  pageNavigationExtras: NavigationExtras = {
    skipLocationChange: true
  };
  skipLocationFlag = true;
  constructor(private router: Router, private storage: Storage,
              private platform: Platform, public toastController: ToastController,
              private afAuth: AngularFireAuth, private readerService: ReaderService, 
              private logService: DebuggingService, private alertController: AlertController,
              private deviceService: DevicePlatformService ) {

      this.platform.ready().then(() => {
        // if (!this.platform.is('pwa') && (this.platform.is('android') || this.platform.is('ios'))) {
        if (this.deviceService.devicePlatform === 'AAPP' || this.deviceService.devicePlatform === 'IAPP'  || 
            this.deviceService.devicePlatform === 'DPWA') {
          this.pageNavigationExtras.skipLocationChange = false;
          this.skipLocationFlag = false;
        }
        this.ifLoggedIn();
      });
    }
    ifLoggedIn() {
      this.storage.get('USER_INFO').then((response) => {
        if (response) {
          this.authState.next(true);
        }
      }).catch(err => {
          this.newLog.Function = 'EBook Auth - Getting user Info';
          this.newLog.Error = err.toString();
          this.logService.addLog(this.newLog);
      });
    }

    login(user, role) {

      console.log('recieved user info in auth guard ', user, 'role', role);
      var user_response = {
        user_id: user.uid,
        user_name: user.displayname === null ? '' : user.displayname,
        user_email: user.email === null ? '' : user.user_email,
        user_role: role
      };
      this.storage.set('USER_INFO', user_response).then((response) => {

        this.router.navigate(['ebook-menu/home'], this.pageNavigationExtras);
        this.authState.next(true);
      }).catch (async err => {

        this.newLog.Function = 'EBook Auth Login - Adding User infor to device storage';
        this.newLog.UserId = user.uid;
        this.newLog.Error = err.toString();
        this.logService.addLog(this.newLog);
      });
    // });
    }
    logout() {
      this.authState.next(false);
      this.webExit.next(true);
      this.storage.remove('USER_INFO').then(() => {
        if (this.deviceService.devicePlatform === 'APWA' || this.deviceService.devicePlatform === 'IPWA'
            || this.deviceService.devicePlatform === 'DPWA' ) {
          const navigationExtras: NavigationExtras = {
            queryParams: {
              exitValue: true
            },
            skipLocationChange: this.skipLocationFlag
          };
          this.router.navigate(['login'], navigationExtras);
        }
        else if (this.deviceService.devicePlatform === 'AAPP' || this.deviceService.devicePlatform === 'IAPP') {
          this.router.navigate(['login']);
        } else {
          this.router.navigate(['thankyou']);
        }
        // this.authState.next(false);
        if (this.deviceService.devicePlatform === 'AAPP' || this.deviceService.devicePlatform === 'IAPP'  || 
            this.deviceService.devicePlatform === 'DPWA') {
        this.afAuth.signOut().catch(async err => {
          console.dir('error in sign out', err);
          this.newLog.Function = 'EBook Auth - Logout';
          this.newLog.Error = err.toString();
          this.newLog.UserId = (await this.afAuth.currentUser).uid;
          this.logService.addLog(this.newLog);
        });
      }
      }).catch( async err => {
        this.newLog.Function = 'EBook Auth - removing user info from device storage';
        this.newLog.Error = err.toString();
        this.newLog.UserId = (await this.afAuth.currentUser).uid;
        this.logService.addLog(this.newLog);
      });
    }
      isAuthenticated() {
        return this.authState.value;
      }

    getUserRole(id) {
        return this.readerService.getReader(id);

      }


      async presentAlert(subh: string, msg: string) {
        return new Promise(async (resolve) => {
        const alert = await this.alertController.create({
          subHeader: subh,
          message: msg,
          buttons: [{
              text: 'بله',
              role: 'cancel',
              handler: data => {
                console.log('Cancel clicked');
                resolve(true);
              }
            }]
        });
        await alert.present();
      });
      }

}
