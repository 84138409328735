import { DebuggingService, Log } from './debugging.service';
import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
// import { map } from 'rxjs/operators';

export interface Reader {
  id?: string;
  Name: string;
  Email: string;
  Role: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReaderService {
  private readersCollection: AngularFirestoreCollection<Reader>;
  newLog: Log = {UserId: '', Function: '', Error: '', Time: null, id: ''};
  constructor( private db: AngularFirestore, private logService: DebuggingService) {
    this.readersCollection = db.collection<Reader>('Readers');
   }

  addReader(reader: Reader) {
    return this.readersCollection.doc(reader.id).set(reader).then(r => {console.log('new reader added successfully');
                                                                        // throw new Error('Add Reader Customer Error');
  })
    .catch(err => {
      this.newLog.Function = 'Reader Service - Adding Reader';
      this.newLog.Error = err.toString();
      this.newLog.UserId = reader.id;
      this.logService.addLog(this.newLog);
      console.log('could not add this new reader');});
  }

  async addAnonymousReader(reader: Reader) {
    const docRef = await this.readersCollection.ref.doc();
    const docId = docRef.id;
    reader.id = docId;
    return this.readersCollection.doc(docId).set(reader).then(r => 
      {
        console.log('new reader added successfully');
        return docId;
        // throw new Error('Add Reader Customer Error');
  })
    .catch(err => {
      this.newLog.Function = 'Reader Service - Adding Reader';
      this.newLog.Error = err.toString();
      this.newLog.UserId = reader.id;
      this.logService.addLog(this.newLog);
      console.log('could not add this new reader');
      return '';
  
  });
  }

  getReader(id) {
    return this.readersCollection.doc<Reader>(id).valueChanges();
  }
}
