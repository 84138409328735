import { DevicePlatformService } from './device-platform.service';
// import { Platform } from '@ionic/angular';
import { DebuggingService, Log } from './debugging.service';
import { EbookAuthService } from './ebook-auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, NavigationExtras } from '@angular/router';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  newLog: Log = {UserId: '', Function: '', Error: '', Time: null, id: ''};
  pageNavigationExtras: NavigationExtras = {
    skipLocationChange: true
  };
  constructor(public authService: EbookAuthService,  private router: Router,  private storage: Storage,
              private logService: DebuggingService, private deviceservice: DevicePlatformService) {

                // if (!this.platform.is('pwa') && (this.platform.is('android') || this.platform.is('ios'))) {
                if (this.deviceservice.devicePlatform === 'AAPP' || this.deviceservice.devicePlatform === 'IAPP') {
                  this.pageNavigationExtras.skipLocationChange = false;
                }
               }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot)
  : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
   let url: string = state.url;
  //  return this.authService.isAuthenticated();
   return this.checkUserLogin(next, url);
  }


  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
    if (this.authService.isAuthenticated()) {
      this.storage.get('USER_INFO').then (userInfo => {
        if (route.data.role && route.data.role.indexOf(userInfo.user_role) === -1) {
          console.log('not allowed due to role mismatch will return to the login page');
          this.router.navigate(['/login'], this.pageNavigationExtras);
          this.newLog.Function = 'Auth Guard - Check user Login';
          this.newLog.Error = 'not allowed due to role mismatch will return to the login page';
          this.logService.addLog(this.newLog);
          return false;
        }
        console.log('allowed will proceed to the next page');
        return true;
      }).catch (async err => {
        this.newLog.Function = 'Auth Gaurd - Check user Login - Getting User info from device storage ';
        this.newLog.Error = err.toString();
        this.logService.addLog(this.newLog);
      });
    }
    console.log('not allowed due to user authentication problem ,  will return to the login page');
    this.router.navigate(['/login'], this.pageNavigationExtras);
    this.newLog.Function = 'Auth Guard - Check user Login';
    this.newLog.Error = 'not allowed due to user authentication problem ,  will return to the login page';
    this.logService.addLog(this.newLog);
    return false;
  }

}
