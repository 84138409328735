import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SplashService {
  public showSubject = new Subject<boolean>();
  constructor() {
    this.showSubject.next(true);
   }
   hideSplash() {
     this.showSubject.next(false);
   }
}
