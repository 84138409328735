import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'farsiNumber'
})
export class FarsiNumberPipe implements PipeTransform {
  persianNumbers = ['۰','۱','۲','۳','۴','۵','۶','۷','۸','۹'];
  transform(value: number, arg): any {
    let newVal: any;
    if (arg === 'number') {
    }
    return newVal;
  }

}
