import { DevicePlatformService } from './device-platform.service';
import { FcmService } from './fcm.service';
import { EbookAuthService } from './ebook-auth.service';
// import { AuthGuard } from './auth-guard.service';
import { SplashService } from './splash.service';
import { Component, Inject } from '@angular/core';

import { NavController, Platform, AlertController, LoadingController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DOCUMENT } from '@angular/common';
import { Subscription, timer } from 'rxjs';
import { NavigationExtras, Router } from '@angular/router';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { FirebaseConfig } from '@ionic-native/firebase-config/ngx';
import * as firebase from 'firebase/app';
import 'firebase/remote-config';
import { environment } from 'src/environments/environment';
import { SwUpdate } from '@angular/service-worker';



firebase.initializeApp(environment.firebaseConfig);
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  showSplash = true;
  subscription: Subscription;
  alertDisplayed = false;
  customInstall: any;
  pageNavigationExtras: NavigationExtras = {
    skipLocationChange: true
  };
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    @Inject(DOCUMENT) private document: Document,
    private splash : SplashService, private authService: EbookAuthService,
    private router: Router,  private navCtrl: NavController, private alertController: AlertController,
    private appVersion: AppVersion, private firebaseConfig: FirebaseConfig,
    private swUpdate: SwUpdate, private fcmService: FcmService , private loadingController: LoadingController,
    private deviceService: DevicePlatformService
  ) {
    // if (!this.platform.is('pwa') && (this.platform.is('android') || this.platform.is('ios'))) {
    //   console.log('will set the skip navigation to false');
    //   this.pageNavigationExtras.skipLocationChange = false;
    // }
    if (this.deviceService.devicePlatform === 'AAPP' || this.deviceService.devicePlatform === 'IAPP') {
      console.log('will set the skip navigation to false');
      this.pageNavigationExtras.skipLocationChange = false;
    }
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.statusBar.overlaysWebView(false); // .styleDefault();
      this.statusBar.backgroundColorByHexString('000000');
      this.document.dir = 'rtl';
      console.log('this platform is ', this.platform.platforms());

      // if (this.platform.is('android')) {
      if (this.deviceService.devicePlatform === 'AAAP' || this.deviceService.devicePlatform === 'IAAP'){
      firebase.remoteConfig().settings = {minimumFetchIntervalMillis: 43200000, fetchTimeoutMillis: 60000};
      firebase.remoteConfig().defaultConfig = {force_update_store_url: 'https://play.google.com/store/apps/details?id=com.expertsdomain.ebooks', force_update_current_version: 0, force_update_required: false};
      // console.log('value of firebase remote config key is ' ,  .getString('force_update_current_version') );
      firebase.remoteConfig().fetch();
      firebase.remoteConfig().activate()
      .then(async fetched => {
        console.log('fetched value', fetched);
        if(fetched) {
          console.log('config is fetched', firebase.remoteConfig().getNumber('force_update_current_version'));
          if (firebase.remoteConfig().getNumber('force_update_current_version') > await this.appVersion.getVersionCode()) {
            this.presentUpdate('به روزرسانی برنامه', 'نسخه جدید موجود است');
          }
        } else {
          console.log('fetched object is ', firebase.remoteConfig().getNumber('force_update_current_version'));
          if (firebase.remoteConfig().getNumber('force_update_current_version') > await this.appVersion.getVersionCode()) {
            this.presentUpdate('به روزرسانی برنامه', 'نسخه جدید موجود است');

          }
        }

      });
      console.log('token found in app component is ', );
      // this.showLoader();
      const deviceId = await this.fcmService.getToken();
      console.log('device ID in app component', deviceId);
      if (deviceId) {
      const {deviceFound, userId, userType} = await this.fcmService.checkDevice(deviceId);

      if (deviceFound) {
        if ( userType === 'AnonymousUser') {
        console.log('device id ', deviceId, ' is found in the database');
        const loggedUser = {uid: userId, displayname: '', email: ''};
        console.log('will consider user as authenticated and move on to home page');
        this.authService.login(loggedUser, 'Reader' );
      }

      } else {
        console.log('device id ', deviceId, ' is not found in the database');
      }
    } else {
      console.log('device id is not found in the database');
    }
      timer(500).subscribe(() => this.showSplash = false);
    }
      //.fetchAndActivate())// .remoteConfig().getString('force_update_current_version')) ;
      // console.log(await this.firebaseConfig.getString('force_update_current_version'));
      // .then(res => {
      //   console.log('firebase remote config version value is', res);
      // }).catch(err => {
      //   console.error(err);
      // });
      if (this.deviceService.devicePlatform !== 'AAAP' && this.deviceService.devicePlatform !== 'IAAP') {
      if (this.swUpdate.available) {
        this.swUpdate.available.subscribe(() => {
          if (confirm('نسخه جدید موجود است ، بارگیری می شود؟')) {
            window.location.reload();
          }
        });
      }
      window.addEventListener('beforeinstallprompt', e => {
        console.log('before install prompt is fired', this.platform);
        e.preventDefault();
        this.customInstall = e;
        this.promoteInstallation('نصب برنامه', 'آیا می خواهید این برنامه را نصب کنید؟');
      });
      timer(3000).subscribe(() => this.showSplash = false);
    }

      this.splash.showSubject.subscribe(show => {
      this.showSplash = show;
    });

      this.authService.authState.subscribe(state => {
        console.log('auth state in app component is ', state);
        if (state) {
          // this.showMessage('test','auth state will go to home page');
          this.router.navigate(['ebook-menu/home'], this.pageNavigationExtras );
        } else {
          if(!this.authService.webExit.getValue()) {
          // this.showMessage('test','auth state, will go back to login page');
          this.router.navigate(['login'], this.pageNavigationExtras);
        }
        }
      });

      this.subscription = this.platform.backButton.subscribeWithPriority(9999, () => {
        console.log('url is ', this.router.url);
        if (this.router.url === '/ebook-menu/home') {
        // do nothing
      if (!this.alertDisplayed) {
        this.alertDisplayed = true;
        this.presentAlert('خروج','آیا می خواهید از برنامه خارج شوید؟');
      }
      console.log('will do nothing');
      } else {
        this.navCtrl.back();
      }
      });
    });
  }

  async presentAlert(subh: string, msg: string) {
    const alert = await this.alertController.create({
      subHeader: subh,
      message: msg,
      buttons: [ {
        text: 'بله',
        handler: () => {
          navigator['app'].exitApp();
          this.alertDisplayed = false;
         }
        },
         {
          text: 'خير',
          role: 'cancel',
          handler: data => {
            console.log('Cancel clicked');
            this.alertDisplayed = false;
          }
        }]
    });
    await alert.present();
  }

async presentUpdate(subh, msg) {
    const alert = await this.alertController.create({
      subHeader: subh,
      message: msg,
      buttons: [ {
        text: 'به روز رسانی',
        handler: () => {
          window.open('https://play.google.com/store/apps/details?id=com.expertsdomain.ebooks','_system');
         }
        }]
    });
    await alert.present();
  }

  async showMessage(subh: string, msg: string) {
    const alert = await this.alertController.create({
      subHeader: subh,
      message: msg,
      buttons: [ 
         {
          text: 'خير',
          role: 'cancel',
          handler: data => {
            console.log('Cancel clicked');
          }
        }]
    });
    await alert.present();
  }


  showLoader() {
    this.loadingController.create({
      spinner:null,
      message:`<ion-spinner name="dots" color="primary"></ion-spinner>`
    }).then(res => {
      res.present();
      res.onDidDismiss().then(dis => {console.log('loading dismissed'); });
    });
    this.hideLoader();
  }
hideLoader() {
  setTimeout(() => {
    this.loadingController.dismiss();
  }, 3000);
}

async promoteInstallation(subh: string, msg: string) {
  const alert = await this.alertController.create({
    subHeader: subh,
    message: msg,
    buttons: [ {
      text: 'بله add',
      handler: () => {
        this.customInstall.prompt();
       }
      },
       {
        text: 'خير cancel',
        role: 'cancel',
        handler: data => {
          console.log('Cancel clicked');
          this.alertDisplayed = false;
        }
      }]
  });
  await alert.present();
}
}
