import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';

export interface Log {
  UserId?: string;
  Function: string;
  Error: string;
  Time: firebase.firestore.Timestamp;
  id: string;
}
@Injectable({
  providedIn: 'root'
})
export class DebuggingService {
  private logsCollection: AngularFirestoreCollection<Log>;
  constructor( private db: AngularFirestore) { 
    this.logsCollection = db.collection<Log>('Logs');
  }

  async addLog(log: Log) {
    const docRef = await this.logsCollection.ref.doc();
    const docId = docRef.id;
    log.id = docId;
    log.Time = firebase.firestore.Timestamp.fromDate(new Date());
    return this.logsCollection.doc(docId).set(log);
  }
}
