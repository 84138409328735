import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class DevicePlatformService {
public devicePlatform: string;
  constructor(private platform: Platform) {
    if (this.platform.is('android')) {
      if (this.platform.is('pwa')){
        this.devicePlatform = 'APWA';
      }
      else if (this.platform.is('cordova')) {
        this.devicePlatform = 'AAPP';
      } else if (this.platform.is('mobileweb')) {
        this.devicePlatform = 'AWEB';
      }
    } else if (this.platform.is('ios')) {
      if (this.platform.is('pwa')){
        this.devicePlatform = 'IPWA';
      }
      else if (this.platform.is('cordova')) {
        this.devicePlatform = 'IAPP';
      } else if (this.platform.is('mobileweb')) {
        this.devicePlatform = 'IWEB';
      }
    } else if (this.platform.is('desktop') || this.platform.is('tablet')) {
      if (this.platform.is('pwa')){
        this.devicePlatform = 'DPWA';
      }
      else {
        this.devicePlatform = 'DWEB';
      }
    } else {
      this.devicePlatform = 'DWEB';
    }
   }
}
