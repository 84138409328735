import { EbookAuthService } from './ebook-auth.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicSelectableModule } from 'ionic-selectable';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Firebase } from '@ionic-native/firebase/ngx';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { HttpClientModule } from '@angular/common/http';
import { File as NativeFile } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { AuthGuard } from './auth-guard.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Camera } from '@ionic-native/Camera/ngx';
import { FarsiNumberPipe } from './farsi-number.pipe';
// import { FirebaseCrashlytics } from '@ionic-native/firebase-crashlytics/ngx';
// import { Crashlytics } from '@ionic-native/firebase-crashlytics';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { FirebaseConfig } from '@ionic-native/firebase-config/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [AppComponent, FarsiNumberPipe],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), IonicStorageModule.forRoot(), AppRoutingModule,
     AngularFireModule.initializeApp(environment.firebaseConfig, 'Ebooks App'),
     AngularFireDatabaseModule, // imports firebase/database, only needed for database features
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFirestoreModule,
    IonicSelectableModule,
    HttpClientModule,
    FontAwesomeModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    NativeFile,
    FileOpener,
    FileTransfer,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    GooglePlus,
    AuthGuard,
    EbookAuthService,
    Firebase,
    Camera,
    AppVersion,
    FirebaseConfig,
    SocialSharing
    // FirebaseCrashlytics
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
