import { AngularFirestore } from '@angular/fire/firestore';
import { DebuggingService, Log } from './debugging.service';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Firebase } from '@ionic-native/firebase/ngx';
import * as firebase from 'firebase/app';

export interface Device {
  UserId?: string;
  UserType: string;
  DeviceId: string;
  Time: firebase.firestore.Timestamp;
}
@Injectable({
  providedIn: 'root'
})
export class FcmService {
  newLog: Log = {UserId: '', Function: '', Error: '', Time: null, id: ''};
  public currToken: string;
  public newUser = false;
  constructor(private firebaseNative: Firebase, private platform: Platform, 
              private logService: DebuggingService, private afs: AngularFirestore) { }
  async getToken() {
    return await this.firebaseNative.getToken().then(token => {
      this.currToken = token;
      return token;
    }).catch(err => {
      this.newLog.Function = 'FCM Service - Get Token';
      this.newLog.Error = err.toString();
      this.logService.addLog(this.newLog);
      return null;
    });
  }
  async addDevice(userId, userType) {
    const regDate = firebase.firestore.Timestamp.fromDate(new Date());
    const newDevice: Device = {UserId: userId, DeviceId: this.currToken, UserType: userType, Time: regDate };

    this.afs.collection('Devices').doc(this.currToken).set(newDevice).catch(err => {
      this.newLog.Function = 'FCM Service - Add Device';
      this.newLog.Error = err.toString();
      this.logService.addLog(this.newLog);
    });

  }

  async checkDevice(deviceId) {
    const deviceRef = await this.afs.collection('Devices').doc<Device>(deviceId).get().toPromise();
    if (deviceRef.exists) {
      console.log('found document!', deviceRef.data());
      return { deviceFound: true , userId: deviceRef.data().UserId, userType: deviceRef.data().UserType} ;
    } else {
      console.log('Document not found');
      this.newUser = true;
      return  { deviceFound: false , userId: '', userType: ''} ;
    }
  }
}
